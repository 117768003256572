import React, { Component } from "react";
// import { Router, Redirect, Link } from "@reach/router";
import { StaticRouter } from "react-router-dom";
//  Use Reach Router instead of React Router to avoid breaking SSR

import loadable from "@loadable/component";

import "./style.scss";

const HomeView = loadable(() => import("./views/HomeView"));

class HomeRoute extends Component {
  render() {
    return (
      <div>
        <StaticRouter>
          <HomeView />
        </StaticRouter>
        <noscript>
          <p>
            <img
              src="https://mediaartslab.matomo.cloud/matomo.php?idsite=1&amp;rec=1"
              style={{ border: 0 }}
              alt=""
            />
          </p>
        </noscript>
      </div>
    );
  }
}

HomeRoute.propTypes = {
};

export default HomeRoute;
